<template>
  <div v-if="stars">
    <div class="stars-badge" v-if="totalStars">
      <img :key="'active' + i" v-for="i in stars" alt="active star" :src="imgPath"/>
      <img :key="'inactive'  + j" v-for="j in inactiveStars" alt="inactive star" src="@/assets/img/world/loyalty/star.svg"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['stars'],
  data () {
    return {
      ratingMap: { 1: 'D', 2: 'C', 3: 'B', 4: 'A' },
      totalStars: 4
    }
  },
  computed: {
    inactiveStars () {
      return this.totalStars - this.stars
    },
    imgPath () {
      return require('@/assets/img/world/loyalty/star_' + this.ratingMap[this.stars] + '.svg')
    }
  }
}
</script>
