<template>
  <div class="world dashboard">
    <div v-if="currentUser" class="banner-wrapper">
      <div class="banner">
        <div class="container">
          <div class="date">{{ date }}</div>
          <h1>{{ $t('Welcome back') }}{{ currentUser.profile.first_name ? ', ' + currentUser.profile.first_name : '' }}</h1>

          <div v-if="filteredPosts.length > 0 || (globals.welcome_title && hasTrans(globals.welcome_title) && globals.welcome_intro && hasTrans(globals.welcome_intro)) || (globals.questions_title && hasTrans(globals.questions_title) && globals.questions_intro && hasTrans(globals.questions_intro))" class="info-blocks">
            <router-link v-if="filteredPosts.length > 0" :to="{name: 'ww.news'}" class="info-blocks__block">
              <div class="flex items-start">
                <img alt="news icon" class="icon" src="@/assets/img/world/dashboard/Nieuws.svg"/>
                <div>
                  <h3>{{ $t('Nieuws') }}</h3>
                  <p>{{ $t('Klik hier en lees het laatste nieuws over Wilms en onze producten!') }}</p>
                </div>
                <img alt="next" src="@/assets/img/world/dashboard/news-next.svg"/>
              </div>
            </router-link>

            <div v-if="globals.welcome_title && hasTrans(globals.welcome_title) && globals.welcome_intro && hasTrans(globals.welcome_intro)" class="info-blocks__block">
              <h3 v-html="getTranslation(globals.welcome_title).value"></h3>
              <p v-html="getTranslation(globals.welcome_intro).value"></p>
            </div>

            <div v-if="globals.questions_title && hasTrans(globals.questions_title) && globals.questions_intro && hasTrans(globals.questions_intro)" class="info-blocks__block">
              <h3 v-html="getTranslation(globals.questions_title).value"></h3>
              <p v-html="getTranslation(globals.questions_intro).value"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-7xl px-5 mx-auto relative pb-20 md:pb-12 pt-10">
      <h2 v-if="latestNewsItem && hasTrans(latestNewsItem)" class="font-700 text-wilmsBlue text-2xl">{{ $t('Laatste nieuws') }}</h2>

      <router-link v-if="latestNewsItem && hasTrans(latestNewsItem)" :to="{ name: 'ww.news.detail', params: { id: latestNewsItem.id } }" class="flex flex-row mb-8 mt-4 max-w-xl latest-news">
        <div class="pr-6">
          <h3 class="text-xl md:text-base text-wilmsBlue uppercase font-700 leading-tight pb-1">
            <a>{{ getTranslation(latestNewsItem).title }}</a>
          </h3>
          <div class="text-wilmsBodyTextGrey pb-1 md:text-sm">
            {{ getTranslation(latestNewsItem).teaser }}
          </div>
          <span class="block font-100 text-wilmsLightBlue font-600 md:hidden">
            {{ latestNewsItem.created.setLocale(activeLocale).toFormat('dd.LL.yyyy') }}
          </span>
        </div>
        <div v-if="images[latestNewsItem.image_file_id]" class="ml-auto image">
          <img :alt="getTranslation(latestNewsItem).title" :rel="getTranslation(latestNewsItem).title" :src="images[latestNewsItem.image_file_id].url" class="block shadow-xl rounded-sm"/>
        </div>
      </router-link>

      <div class="menu">
        <h2 class="font-700 text-wilmsBlue text-2xl">{{ $t('Menu') }}</h2>

        <div class="loyalty-chosen-dealer">
          <div>{{ $t('Gekozen dealer:') }}</div>
          <v-select
            v-if="checkPermissions(['dealer.view'])"
            id="dealerSwitcher"
            v-model="choice"
            :filterable="false"
            :options="filteredDealers"
            :value="dealer"
            label="name"
            @input="selectDealer"
            @search="findDealersSearch">
            <li slot="list-footer" class="pagination">
              <button :disabled="dealersSelectPage === 0" @click="findDealersPage(dealersSelectPage - 1)">Prev</button>
              <div class="page">{{ dealersSelectPageTotal.min }}-{{ dealersSelectPageTotal.max }} / {{ dealersSelectTotal }}</div>
              <button :disabled="filteredDealers.length !== dealersSelectPerPage" @click="findDealersPage(dealersSelectPage + 1)">Next</button>
            </li>
          </v-select>
          <v-select
            v-else
            id="dealerSwitcher"
            v-model="choice"
            :options="dealers"
            :value="dealer"
            label="name"
            @input="selectDealer">
          </v-select>

          <router-link v-if="dealer && (checkPermissions(['dealer.editOwn']) || checkPermissions(['dealer.edit']))" :to="{ name: 'ww.dealer' }" class="btn btn-inverse" data-cy="dealer-button">{{ $t('Dealerinformatie') }}</router-link>
        </div>

        <div class="mt-4 grid grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-8" v-if="currentUser">
          <template v-for="menuItem in menuItems">
            <div :key="'item-' + menuItem.id" v-if="(menuItem.id === 2 && currentUser.profile.calculator_access) || menuItem.id !== 2" data-cy="menu-item">
              <div :class="{'self-start': menuItem.type === 'link'}" class="shadow-xl rounded-sm bg-white h-full">
                <h3 v-if="menuItem.type === 'category'" class="text-wilmsBlue text-xl font-700 p-4 pb-3 border-b">{{ getTranslation(menuItem).name }}</h3>
                <a v-else :href="menuItem.type === 'link' ? link(getTranslation(menuItem).url) : images[getTranslation(menuItemChild).file_id]" class="text-wilmsBlue text-xl font-700 p-4 pb-3 block border-b">{{ getTranslation(menuItem).name }}</a>

                <ul v-if="menuItem.children.length > 0" class="p-4">
                  <li v-for="menuItemChild in menuItem.children" :key="'item-child-' + menuItemChild.id" class="relative pb-4">

                    <router-link v-if="menuItemChild.type === 'category'" @click="gtagEvent(menuItemChild)" :to="{name: 'ww.menuitem', params: {id: menuItemChild.id }}">
                      <div class="flex items-start">
                        <div class="w-6 mr-2 text-center">
                          <img v-if="images[menuItemChild.icon_file_id]" :src="images[menuItemChild.icon_file_id].url" alt="icon" class="inline" width="16"/>
                          <img v-else alt="technical icon" class="inline" src="@/assets/img/world/dashboard/Technische fiches.svg"/>
                        </div>
                        <div class="content">
                          <h4 class="font-700 leading-none text-base">
                            {{ getTranslation(menuItemChild).name }}
                          </h4>
                          <span class="block text-sm leading-none font-100 text-wilmsGrey">{{ getFileCount(menuItemChild.children) }} {{ $t('items') }}</span>
                        </div>
                        <img alt="next icon" class="ml-auto" src="@/assets/img/world/dashboard/list-next.svg"/>
                      </div>
                    </router-link>

                    <a v-if="menuItemChild.type === 'link'" @click="gtagEvent(menuItemChild)" :href="link(getTranslation(menuItemChild).url)">
                      <div class="flex items-start">
                        <div class="w-6 mr-2 text-center">
                          <img v-if="images[menuItemChild.icon_file_id]" :src="images[menuItemChild.icon_file_id].url" alt="icon" class="inline" width="16"/>
                          <img v-else alt="technical icon" class="inline" src="@/assets/img/world/dashboard/Technische fiches.svg"/>
                        </div>
                        <div class="content">
                          <h4 class="font-700 leading-none text-base">
                            {{ getTranslation(menuItemChild).name }}
                          </h4>
                          <span class="block text-sm leading-none font-100 text-wilmsGrey">{{ $t('Link') }}</span>
                        </div>
                        <img alt="next icon" class="ml-auto" src="@/assets/img/world/dashboard/list-next.svg"/>
                      </div>
                    </a>

                    <a v-if="menuItemChild.type === 'file'" @click="gtagEvent(menuItemChild)" :href="images[getTranslation(menuItemChild).file_id].url">
                      <div class="flex items-start">
                        <div class="w-6 mr-2 text-center">
                          <img v-if="images[menuItemChild.icon_file_id]" :src="images[menuItemChild.icon_file_id].url" alt="icon" class="inline" width="16"/>
                          <img v-else alt="technical icon" class="inline" src="@/assets/img/world/dashboard/Technische fiches.svg"/>
                        </div>
                        <div class="content">
                        <span class="font-700 leading-none text-base">
                          {{ getTranslation(menuItemChild).name }}
                        </span>
                          <span class="block text-sm leading-none font-100 text-wilmsGrey">{{ $t('Bestand') }}</span>
                        </div>
                        <img alt="next icon" class="ml-auto" src="@/assets/img/world/dashboard/list-next.svg"/>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <router-link v-if="hasLoyaltyAccess && dealer.loyalty" :to="{ name: 'ww.loyalty' }" class="bg-white flex shadow-xl rounded-sm relative wilmsAcademy items-center p-4 self-start" data-cy="loyalty-button">
            <h3 class="flex-1 gap-4 items-center flex text-wilmsBlue text-xl font-700">{{ $t('Sterrenprogramma') }}
              <Stars :stars="dealer.loyalty.stars"/>
            </h3>
          </router-link>
        </div>
      </div>
    </div>

    <!--    <Snackbar v-if="dealer"/>-->
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import moment from 'moment'
import { DateTime } from 'luxon'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Stars from '@/components/world/Stars.vue'
// import Snackbar from '@/components/world/Snackbar'

export default {
  data () {
    return {
      latestNewsItem: null,
      menuItemsRaw: [],
      menuItems: [],
      filteredDealers: [],
      dealersSelectPage: 0,
      dealersSelectSearch: '',
      hasNextDealersSelectPage: false,
      hasPrevDealersSelectPage: false,
      dealersSelectPerPage: 10,
      dealersSelectTotal: 0,
      icons: [],
      globals: {}
    }
  },
  components: {
    Stars,
    vSelect
  },
  async mounted () {
    this.title = 'Dashboard'
    await this.fetchLatestHighlightedNewsItem()
    await this.fetchMenuItems()

    this.globals.welcome_title = (await ApiService.fetchGlobal('dashboard', 'welcome.title')).data
    this.globals.welcome_intro = (await ApiService.fetchGlobal('dashboard', 'welcome.intro')).data

    this.globals.questions_title = (await ApiService.fetchGlobal('dashboard', 'questions.title')).data
    this.globals.questions_intro = (await ApiService.fetchGlobal('dashboard', 'questions.intro')).data

    await this.$store.dispatch('NewsModule/fetchNews')
    await this.$store.dispatch('fetchCurrentUserDealers')

    await this.setDealer()

    if (this.checkPermissions(['dealer.view'])) {
      await this.findDealers()
    }

    this.setMenuItems()
  },
  methods: {
    async findDealersSearch (search) {
      this.dealersSelectPage = 0
      this.dealersSelectSearch = search
      await this.findDealers()
    },
    async findDealers () {
      await ApiService.fetchDealers(this.dealersSelectPage, this.dealersSelectPerPage, this.dealersSelectSearch, 'dealer.name', 'asc').then(res => {
        this.filteredDealers = res.data.dealers
        this.dealersSelectTotal = res.data.total
      })
    },
    async findDealersPage (page) {
      this.dealersSelectPage = page
      await this.findDealers()
    },
    getFileCount (children) {
      let count = 0
      if (children && children.length > 0) {
        let hasSubFiles = false

        children.forEach((child, i) => {
          count += child.children.filter(child => {
            return (child.type === 'file' || child.type === 'link') && this.hasTrans(child)
          }).length

          if (count > 0) {
            hasSubFiles = true
          }
        })

        if (!hasSubFiles) {
          count += children.filter(child => {
            return (child.type === 'file' || child.type === 'link') && this.hasTrans(child)
          }).length
        }
      }
      return count
    },
    async selectDealer (choice) {
      if (choice) {
        localStorage.setItem('chosenDealerId', choice.id)
      } else {
        localStorage.removeItem('chosenDealerId')
      }
      await this.setDealer()
      this.setMenuItems()
    },
    setMenuItems () {
      // Hide orders menu item if no dealer selected
      // Hide menu items without the translation of the currently selected language
      const menuItems = []

      this.menuItemsRaw.forEach((menuItem, i) => {
        if (this.hasTrans(menuItem)) {
          const filteredMenuItem = JSON.parse(JSON.stringify(menuItem))
          if ((!filteredMenuItem.dealer_required || (filteredMenuItem.dealer_required && this.dealer)) && this.hasTrans(filteredMenuItem)) {
            filteredMenuItem.children = menuItem.children.filter(menuItemChild => ((!menuItemChild.dealer_required || (menuItemChild.dealer_required && this.dealer)) && this.hasTrans(menuItemChild)))
            menuItems.push(filteredMenuItem)
          }
        }
      })

      this.menuItems = menuItems
    },
    fetchLatestHighlightedNewsItem: async function () {
      this.latestNewsItem = (await ApiService.fetchLatestHighlightedNewsItem()).data

      if (this.latestNewsItem) {
        this.latestNewsItem.created = DateTime.fromISO(this.latestNewsItem.created)
        this.imagesLoading = true
        await this.$store.dispatch('fetchImages', { objects: [this.latestNewsItem] })
        this.imagesLoading = false
      }
    },
    fetchMenuItems: async function () {
      const res = await ApiService.fetchMenuItems()
      this.menuItemsRaw = res.data

      let icons = []

      this.menuItemsRaw.forEach((item) => {
        icons = icons.concat(item.children)
      })

      await this.$store.dispatch('fetchImages', { objects: icons, value: 'file_id' })
      await this.$store.dispatch('fetchImages', { objects: icons, value: 'icon_file_id' })
    },
    gtagEvent (menuItem) {
      if (menuItem && menuItem.ga_event) {
        this.$gtag.event(menuItem.ga_event, { event_category: 'menu_items' })
      }
    },
    link (url) {
      if (url) {
        return this.dealer ? url.replace('{dealerClientNumber}', this.dealer.client_number).replace('{dealerName}', this.dealer.name) : url
      }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    },
    date () {
      const date = moment().locale(this.activeLocale).format('dddd, DD MMMM YYYY')
      return date.charAt(0).toUpperCase() + date.slice(1)
    },
    dealers () {
      return this.$store.getters.getCurrentUserDealers
    },
    posts () {
      return this.$store.getters['NewsModule/getNews']
    },
    filteredPosts () {
      return this.posts.filter(post => this.hasTrans(post))
    },
    dealersSelectPageTotal: function () {
      let offsetMax = this.dealersSelectPerPage * (this.dealersSelectPage + 1)
      const offsetMin = offsetMax - this.dealersSelectPerPage + 1

      if (offsetMax > this.dealersSelectTotal) {
        offsetMax = this.dealersSelectTotal
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
